<template>
  <v-dialog v-model="dialog" width="1200" :retain-focus="false">
    <template v-slot:activator="{ on, attrs }">
      <v-card v-bind="attrs" v-on="on" color="orange lighten-2" class="white--text flex-nowrap my-auto"
        @click.stop="listar" tile width="500" height="112">
        <v-card-title class="py-auto px-8 my-auto">
          Acompanhe as suas sugestões
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <!-- <v-col class="text-right white--text pa-1">
              <div v-for="(g, i) in count_grupos_sugestao" :key="`id-${i}`">
                {{ g.text }}: {{ g.qtd }}
              </div>
              
                <div v-if="adm">
                    Novos Recebidos: {{count_grupos_sugestao.num_sugestoes}}<br> 
                    Respondidos: {{atendidos}}
                  </div>
                  <div v-else-if="ava">
                    Recebidos: {{count_grupos_sugestao.enviado_para_avaliador}}
                  </div>
                  <div v-else>
                    Enviados com sucesso: {{count_grupos_sugestao.num_sugestoes}}<br> 
                    Respondidos: {{atendidos}}
                  </div>
                
            </v-col> -->
          </v-row>
        </v-card-text>
      </v-card>
    </template>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="10">

            Minhas sugestões
          </v-col>
          <v-col cols="1" class="text-right">
            <v-btn outlined icon @click="listar">
              <v-icon>mdi-update</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="1" class="text-right">
            <v-btn outlined icon @click="close">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
       <v-data-table :headers="headers" :items="lista_1_array" item-key="name" class="elevation-1" :search="search"
        :loading="loading">
        <template v-slot:top>
          <v-text-field v-model="search" label="Pesquisar" class="mx-4"></v-text-field>
        </template>
        <template v-slot:[`item.resultado`]="{ item }">
          <v-chip class="white--text" label outlined :color="item.resultado.cor">
            {{ item.resultado.text }}
          </v-chip>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-row class="text-end">
            <v-col cols="2" class="text-end">
              <Sugestao :current-item="item" />
            </v-col>
            <v-col cols="2" class="text-end">
              <Editar :current-item="item" :is-ava="isAva" :is-adm="isAdm" />
            </v-col>
          </v-row>
        </template>
      </v-data-table> 
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
/* implementar nesta tela, dados do usuario para sua garantia, assim como dados de prazos e regras. */
export default {
  name: 'ListaSugestoes',
  components: {
    Sugestao: () => import('./Sugestao'),
    Editar: () => import('./Edit')
  },
  props: {
    usuario: { type: Object },
    isAdm: { type: Boolean },
    isAva: { type: Boolean }
  },
  data: () => ({
    dialog: false,
    tab: null,
    search: '',
    cfg: {},
    headers: [],
  }),
  computed: {
    ...mapState(['lista_1_array', 'count_grupos_sugestao']),
    ...mapGetters(['perm', 'adm', 'ava', 'loading']),
    atendidos() {
      return this.count_grupos_sugestao.num_sugestoes - this.count_grupos_sugestao.num_sugestoes_status_default
    },
    isActive() {
      return true
    }
  },
  methods: {
    close() {
      this.dialog = false
    },
    ...mapActions(['loadConfig']),


    listar() {
      this.$store.dispatch('lista', {
        method: 'get',
        url: 'informe/sugestoes/',
        instancia: 'lista_1_array'
      })
      this.$store.dispatch('btnLoading', false)
    },
    get_count_sugestoes() {
      this.$store.dispatch('count_grupos_sugestao', { url: "informe/sugestoes/?grupo=grupo" })
    }

  },
  mounted() {
    this.get_count_sugestoes()
    this.listar()
    this.$store.dispatch('adm', this.isAdm)
    this.$store.dispatch('ava', this.isAva)
    this.headers = [
      { text: 'ID', align: 'start', sortable: true, value: 'id', },
      { text: 'TITULO', align: 'start', sortable: true, value: 'titulo', },
      { text: 'STATUS', align: 'center', sortable: true, value: 'resultado', },
      { text: 'action', align: 'center', sortable: true, value: 'actions' },
    ]
    /**if(this.perm){
    this.headers = [
      { text: '#',align: 'start',sortable: true, value: 'id',},
      { text: 'Título',align: 'start',sortable: true, value: 'titulo',},
      { text: 'Andamento da sua sugestão',align: 'start',sortable: true, value: 'ultimo_movimento.resultado',},
      { text: 'Ver',align: 'center',sortable: true, value: 'actions'},
      { text: 'Editar',align: 'center',sortable: true, value: 'edit'},
    ]
    }else{
      this.headers = [
        { text: 'Id',align: 'start',sortable: true, value: 'id',},
        { text: 'Título',align: 'start',sortable: true, value: 'titulo',},
        { text: 'Andamento da sua sugestão',align: 'start',sortable: true, value: 'ultimo_movimento.resultado',},
        { text: 'Ver',align: 'center',sortable: true, value: 'actions'},
      ]
    } */
  }
}
</script>