var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"1200","retain-focus":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-card',_vm._g(_vm._b({staticClass:"white--text flex-nowrap my-auto",attrs:{"color":"orange lighten-2","tile":"","width":"500","height":"112"},on:{"click":function($event){$event.stopPropagation();return _vm.listar.apply(null, arguments)}}},'v-card',attrs,false),on),[_c('v-card-title',{staticClass:"py-auto px-8 my-auto"},[_vm._v(" Acompanhe as suas sugestões ")]),_c('v-card-text',[_c('v-row',{attrs:{"dense":""}})],1)],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_vm._v(" Minhas sugestões ")]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"outlined":"","icon":""},on:{"click":_vm.listar}},[_c('v-icon',[_vm._v("mdi-update")])],1)],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"outlined":"","icon":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.lista_1_array,"item-key":"name","search":_vm.search,"loading":_vm.loading},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Pesquisar"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.resultado",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"white--text",attrs:{"label":"","outlined":"","color":item.resultado.cor}},[_vm._v(" "+_vm._s(item.resultado.text)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"text-end"},[_c('v-col',{staticClass:"text-end",attrs:{"cols":"2"}},[_c('Sugestao',{attrs:{"current-item":item}})],1),_c('v-col',{staticClass:"text-end",attrs:{"cols":"2"}},[_c('Editar',{attrs:{"current-item":item,"is-ava":_vm.isAva,"is-adm":_vm.isAdm}})],1)],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }